import clsx from "clsx"
import React from "react"
import { observer } from "mobx-react-lite"

import usePopper from "@components/hooks/usePopper"
import Popper from "@components/ui/Dropdown/Popper"
import Box from "@components/ui/Dropdown/Box"
import ListItem from "@components/ui/ListItem/ListItem"
import { filterByQuery } from "@utils/textUtils"
import { Point } from "@framework/types/common"

import { useMatrixContext } from "../MatrixContext"
import HighlightedText from "../parser/FormulaPreview"
import CellEditorState from "../state/CellEditorState"
import RichTextarea from "./RichTextarea"
import FunctionListItem from "./FunctionListItem"
import { FunctionDescription } from "../types"

import styles from "./TextEditor.module.sass"

type TextEditorProps = {
  control: CellEditorState
}

const TextEditor: React.FC<TextEditorProps> = observer(({ control }) => {
  const { grid, selectedRange, editManager } = useMatrixContext()

  const [activeOption, setActiveOption] = React.useState<number>(-1)

  const [popperNode, setPepperNode] = React.useState<HTMLElement | null>(null)
  const [containerNode, setContainerNode] = React.useState<HTMLElement | null>(
    null
  )

  const inputRef = React.useRef<HTMLTextAreaElement | null>(null)

  const index = selectedRange.origin

  const validationOptions = useValidationOptions(index)

  const formulaOptions = useFormulaAutocompleteOptions(index)

  const options = validationOptions || formulaOptions || []
  const optionType =
    validationOptions === options
      ? "VALIDATION"
      : formulaOptions === options
      ? "FUNCTION"
      : null
  const hasOptions = optionType != null

  const handleFocus = () => {
    const node = inputRef.current
    if (node) {
      node.focus()
      node.selectionStart = node.value.length
      node.selectionEnd = node.value.length
    }
  }

  const selectOption = (it: string) => {
    control.setInput(it)
    editManager.submitCell()
  }

  const selectFunction = (it: FunctionDescription) => {
    control.applyFunctionSuggestion(it)
    handleFocus()
  }

  const renderSuggestions = () => {
    if (validationOptions != null)
      return (
        <OptionsList
          options={validationOptions}
          renderItem={renderSimpleItem}
          focused={activeOption}
          onFocus={setActiveOption}
          onSelect={selectOption}
        />
      )

    if (formulaOptions != null)
      return (
        <OptionsList<FunctionDescription>
          options={formulaOptions}
          focused={activeOption}
          onFocus={setActiveOption}
          renderItem={renderFunction}
          onSelect={selectFunction}
        />
      )

    return null
  }

  const { isActive, style } = usePopper(containerNode, popperNode, {
    placement: "bottom-start",
    disabled: !hasOptions,
    defaultValue: hasOptions && control.autoFocusCell,
  })

  React.useEffect(() => {
    if (control.autoFocusCell) handleFocus()
  }, [containerNode])

  React.useEffect(() => {
    setActiveOption(options.length > 0 ? 0 : -1)
  }, [options.length])

  return (
    <div className={clsx(styles.root)} ref={setContainerNode}>
      <RichTextarea
        ref={inputRef}
        className={clsx(styles.textarea)}
        style={{
          minWidth: grid.getCellWidth(index.x),
          maxWidth: Math.max(300, grid.getCellWidth(index.x)),
          minHeight: grid.getCellHeight(index.y),
          maxHeight: "50vh",
        }}
        value={control.input}
        onChange={(e: any) => {
          control.setInput(e.target.value)
        }}
        onSelect={(e) => {
          control.setSelection({
            start: e.currentTarget.selectionStart,
            end: e.currentTarget.selectionEnd,
          })
        }}
        onKeyDown={(e) => {
          if (e.key === "ArrowDown") {
            setActiveOption((prev) =>
              prev + 1 >= options.length ? 0 : prev + 1
            )
            e.preventDefault()
          }

          if (e.key === "ArrowUp") {
            setActiveOption((prev) =>
              prev - 1 < 0 ? options.length - 1 : prev - 1
            )
            e.preventDefault()
          }

          if (e.key === "Enter" && activeOption > -1) {
            if (optionType == null) return

            if (optionType === "VALIDATION" && validationOptions)
              control.setInput(validationOptions[activeOption])

            if (optionType === "FUNCTION" && formulaOptions) {
              selectFunction(formulaOptions[activeOption])
              e.stopPropagation()
            }

            e.preventDefault()
          }
        }}
      >
        <HighlightedText value={control.input} tree={control.formula} />
      </RichTextarea>

      <Popper ref={setPepperNode} style={style} isActive={isActive}>
        {renderSuggestions()}
      </Popper>
    </div>
  )
})

export default TextEditor

type SelectCallback<T> = (option: T) => void

type RenderCallback<T> = {
  option: T
  index: number
  active: number
  onFocus?: (index: number) => void
  onSelect?: SelectCallback<T>
}

interface OptionsListProps<T = string> {
  options: T[]
  focused?: number
  onFocus?: (index: number) => void
  onSelect?: SelectCallback<T>
  renderItem: (props: RenderCallback<T>) => React.ReactNode
}

const OptionsList = observer(
  <T,>({
    options,
    focused = -1,
    onFocus,
    onSelect,
    renderItem,
  }: OptionsListProps<T>) => {
    return (
      <Box color="primary" className={styles.dropdown}>
        {options.map((option, index) =>
          renderItem({ option, index, active: focused, onFocus, onSelect })
        )}
      </Box>
    )
  }
)

const renderSimpleItem = (props: RenderCallback<string>) => (
  <ListItem
    key={props.option}
    onClick={() => props.onSelect?.(props.option)}
    focused={props.active === props.index}
    onMouseEnter={() => props.onFocus?.(props.index)}
  >
    {props.option}
  </ListItem>
)

const renderFunction = (props: RenderCallback<FunctionDescription>) => (
  <ListItem
    key={props.option.name}
    onClick={() => props.onSelect?.(props.option)}
    focused={props.active === props.index}
    onMouseEnter={() => props.onFocus?.(props.index)}
  >
    <FunctionListItem item={props.option}>{props.option}</FunctionListItem>
  </ListItem>
)

const useValidationOptions = (index: Point) => {
  const { editManager, validationManager } = useMatrixContext()

  const control = editManager.activeCellState

  if (control == null)
    throw new Error(
      "useValidationOptions should be used in cell editing context"
    )

  const cell = editManager.getCellAtPoint(index)
  const options = cell.validationRule?.list
  const query = control.input

  return React.useMemo(() => {
    if (options == null) return null
    if (!control.touched) return options
    const filtered = filterByQuery(options, query)

    if (filtered.length) return filtered

    return null
  }, [options, query])
}

const useFormulaAutocompleteOptions = (index: Point) => {
  const { editManager } = useMatrixContext()

  const control = editManager.activeCellState

  if (control == null)
    throw new Error(
      "useFormulaAutocompleteOptions should be used in cell editing context"
    )

  return React.useMemo(() => {
    if (control.suggestion?.functions.length) {
      return control.suggestion?.functions
    }
    return null
  }, [control.suggestion?.functions?.length])
}
