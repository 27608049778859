import { makeAutoObservable } from "mobx"

import MatrixStore from "./MatrixStore"
import { CellValidationRule } from "../types"

export class CellValidationManager {
  // injections
  private context: MatrixStore

  // state

  rules: Map<string, CellValidationRule>

  constructor(config: {
    context: MatrixStore
    snapshot?: Record<string, CellValidationRule>
  }) {
    this.context = config.context

    this.rules = config.snapshot
      ? new Map(Object.entries(config.snapshot))
      : new Map()

    makeAutoObservable(this)
  }

  getRuleByName = (ruleId: string) => {
    const rule = this.rules.get(ruleId)
    if (rule == null) throw new Error("Invalid validation rule name")
    return rule
  }

  hasRule = (ruleId: string) => {
    return this.rules.has(ruleId)
  }

  registerValidationRule = (ruleId: string, rule: CellValidationRule) => {
    if (this.rules.has(ruleId))
      throw new Error(`Validation rule ${ruleId} already registered`)

    this.rules.set(ruleId, rule)
  }

  removeValidationRule = (name: string) => {
    this.rules.delete(name)
  }

  serialize = () => {
    return Object.fromEntries([...this.rules.entries()])
  }
}

export default CellValidationManager
