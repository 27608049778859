import { makeAutoObservable, runInAction } from "mobx"
import find from "lodash/find"
import cloneDeep from "lodash/cloneDeep"

import { filterByQuery } from "@utils/textUtils"

import { Parser, tokenize } from "../parser"
import EditManager from "./EditManager"
import FormulaExecutor from "./FormulaExecutor"
import CellValidationManager from "./CellValidationManager"
import { CellSnapshot, CellState } from "../types"

const formulaParser = new Parser()

const defaultState: CellState = {
  input: "",
  value: "",
  error: null,
  formula: null,
  isLoading: false,
}

class CellManager {
  // injections

  editManager: EditManager

  validationManager: CellValidationManager

  // state
  id: string

  state: CellState

  validationRuleId: string | null

  task: FormulaExecutor | null = null

  constructor(options: {
    id: string
    manager: EditManager
    validationManager: CellValidationManager
    initialState?: CellState
    initialValidationRuleId?: string | null
  }) {
    this.id = options.id

    this.editManager = options.manager
    this.validationManager = options.validationManager

    this.validationRuleId = options.initialValidationRuleId ?? null

    this.state = {
      ...defaultState,
      ...(options.initialState ?? null),
    }

    makeAutoObservable(this)
  }

  get value() {
    return this.state.value
  }

  get validationRule() {
    if (this.validationRuleId == null) return null
    return this.validationManager.getRuleByName(this.validationRuleId)
  }

  apply = async () => {
    try {
      this.state.formula = null
      this.state.error = null
      this.state.isLoading = true

      const inputValue = this.state.input

      if (inputValue && this.validationRuleId != null) {
        const validationRule = this.validationManager.getRuleByName(
          this.validationRuleId
        )

        if (validationRule.type === "OPTION" && validationRule.list.length) {
          this.state.isLoading = false

          const option = filterByQuery(validationRule.list, inputValue)

          if (option.length === 1) {
            const onlyOption = option[0]
            this.state.value = onlyOption
            this.state.input = onlyOption
            return
          }

          const exactMatch = find(
            option,
            (it) => it.toLowerCase() === inputValue.toLowerCase()
          )

          if (exactMatch != null) {
            this.state.value = exactMatch
            this.state.input = exactMatch
            return
          }

          this.state.input = ""
          this.state.value = ""
          return
        }
      }

      if (!inputValue?.startsWith("=")) {
        this.state.value = inputValue
        this.state.isLoading = false
        return
      }

      const tokens = tokenize(inputValue.substring(1))

      this.state.formula = formulaParser.parse(tokens)

      if (!this.editManager.isValidateRefs(this.id, this.state.formula.refs))
        throw new Error("#REF!")

      const formulaTask = new FormulaExecutor({
        manager: this.editManager,
        formula: this.state.formula,
      })

      this.task = formulaTask

      await formulaTask.run()

      if (this.task.status === "completed") {
        runInAction(() => {
          this.state.value = formulaTask.result
          this.state.isLoading = false
        })
        return
      }

      if (this.task.status === "failed") {
        runInAction(() => {
          this.state.value = ""
          this.state.error = formulaTask.error
          this.state.isLoading = false
        })
        return
      }
    } catch (error: any) {
      runInAction(() => {
        this.state.error =
          typeof error.message === "string" ? error.message : "Unexpected error"
        this.state.isLoading = false
      })
    }
  }

  setInput = (newInput: any = "") => {
    this.state = {
      input: newInput.trim(),
      isLoading: false,
      formula: null,
      error: null,
      value: null,
    }
  }

  setValidationRule = (validation: string | null) => {
    this.validationRuleId = validation
  }

  serialize = (): CellSnapshot => {
    return {
      state: cloneDeep(this.state),
      validationRuleId: this.validationRuleId,
    }
  }

  cleanUp = () => {
    this.setInput("")
    this.apply()
  }

  reset = () => {
    this.state = {
      ...defaultState,
    }
    this.validationRuleId = null
  }
}

export default CellManager
