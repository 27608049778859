import { makeAutoObservable } from "mobx"
import max from "lodash/max"

import { Point, Range } from "@framework/types/common"
import { copyToClipboard, readClipboard } from "@utils/clipboard"

import CellManager from "./CellManager"
import {
  stringifyPoint,
  parsePointString,
  contains,
  forEachOfRange,
  includes,
  intersection,
  makeRange,
  rangeSize,
  refToRange,
} from "../utils"
import CellEditorState from "./CellEditorState"
import MatrixStore from "./MatrixStore"
import FunctionManager from "./FunctionManager"
import { CellSnapshot } from "../types"

class EditManager {
  // injections
  private context: MatrixStore

  // state
  activeCellId: string | null = null

  activeCellState: CellEditorState | null = null

  functionManager: FunctionManager

  get isEditing() {
    return this.activeCellId != null
  }

  get isCellEditing() {
    const { activeCellId } = this
    return (point: Point) => activeCellId === stringifyPoint(point)
  }

  data: Map<string, CellManager>

  constructor(config: {
    context: MatrixStore
    snapshot?: Record<string, CellSnapshot>
  }) {
    this.context = config.context

    this.functionManager = new FunctionManager({ manager: this })

    this.data = config.snapshot
      ? new Map(
          Object.entries(config.snapshot).map(([cellId, cellSnapshot]) => [
            cellId,
            new CellManager({
              manager: this,
              validationManager: this.context.validationManager,
              id: cellId,
              initialState: cellSnapshot.state,
              initialValidationRuleId: cellSnapshot.validationRuleId,
            }),
          ])
        )
      : new Map()

    makeAutoObservable(this)
  }

  editCell = (
    point: Point,
    options: { initialValue?: string; focusCell?: boolean } = {}
  ) => {
    const cell = this.getCellAtPoint(point)

    this.activeCellState = new CellEditorState({
      functionManager: this.functionManager,
      initialValue: options.initialValue ?? cell.state.input,
      autoFocusCell: options.focusCell,
    })

    this.activeCellId = stringifyPoint(point)
  }

  initCell = (point: Point, value: string | number) => {
    const cell = this.getCellAtPoint(point)
    cell.setInput(value)
    cell.apply()
  }

  submitCell = () => {
    const cell = this.getActiveCell()

    if (cell == null) return

    cell.setInput(this.activeCellState?.normalize())
    cell.apply()

    this.activeCellId = null
    this.activeCellState = null
  }

  cancelCell = () => {
    this.activeCellId = null
    this.activeCellState = null
  }

  getRefValues = (refs: string[]): Record<string, any | any[]> => {
    return Object.fromEntries(refs.map((ref) => [ref, this.getRefValue(ref)]))
  }

  getRefValue = (ref: string): any | any[] => {
    const refRange = refToRange(ref)

    if (rangeSize(refRange) === 1) {
      return this.getCellAtPoint(refRange.start).value
    }

    const cols = refRange.end.x - refRange.start.x + 1
    const rows = refRange.end.y - refRange.start.y + 1
    const values = []

    for (let xi = 0; xi < cols; xi += 1) {
      for (let yi = 0; yi < rows; yi += 1) {
        values.push(
          this.getCellAtPoint({
            x: refRange.start.x + xi,
            y: refRange.start.y + yi,
          }).value
        )
      }
    }

    return values
  }

  isValidateRefs = (cellId: string, refs: string[]) => {
    const origin = parsePointString(cellId)
    const boundary = this.context.grid.rect
    return refs.every((ref) => {
      const refRange = refToRange(ref)
      return !includes(refRange, origin) && contains(boundary, refRange)
    })
  }

  get findCell() {
    return (point: Point) => {
      const id = stringifyPoint(point)

      const cell = this.data.get(id)
      return cell ?? null
    }
  }

  get getCellAtPoint() {
    const { getCell } = this
    return (point: Point) => {
      const id = stringifyPoint(point)
      return getCell(id)
    }
  }

  get getActiveCell() {
    const { activeCellId: id, getCell } = this
    return () => {
      if (id == null) return null
      return getCell(id)
    }
  }

  get getCell() {
    return (id: string) => {
      const cell = this.data.get(id)
      if (cell != null) return cell

      this.data.set(
        id,
        new CellManager({
          id,
          manager: this.context.editManager,
          validationManager: this.context.validationManager,
        })
      )
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.data.get(id)!
    }
  }

  cleanUpRange = (range: Range<Point>) => {
    forEachOfRange(range, (point) => {
      this.getCellAtPoint(point).cleanUp()
    })
  }

  initMatrix = (value: string[][]) => {
    const rows = value.length
    for (let y = 0; y < rows; y += 1) {
      const cols = value[y].length
      for (let x = 0; x < cols; x += 1) {
        this.initCell({ x, y }, value[y][x])
      }
    }
  }

  copy = () => {
    const { range } = this.context.selectedRange

    const result: string[][] = []

    forEachOfRange(range, (point) => {
      const { value } = this.getCellAtPoint(point)

      result[point.y - range.start.y] = result[point.y - range.start.y] ?? []
      result[point.y - range.start.y][point.x - range.start.x] = value
    })

    copyToClipboard(stringifyClipboardMatrix(result))
  }

  cut = () => {
    const { range } = this.context.selectedRange

    const result: string[][] = []

    forEachOfRange(range, (point) => {
      const cell = this.getCellAtPoint(point)

      const { value } = cell

      result[point.y - range.start.y] = result[point.y - range.start.y] ?? []
      result[point.y - range.start.y][point.x - range.start.x] = value

      cell.cleanUp()
    })

    copyToClipboard(stringifyClipboardMatrix(result))
  }

  reset = () => {
    const { range } = this.context.selectedRange

    forEachOfRange(range, (point) => {
      const cell = this.getCellAtPoint(point)
      cell.reset()
    })
  }

  paste = async () => {
    const text = await readClipboard()

    if (!text) return

    const values = parseClipboardMatrix(text)

    const size = getMatrixSize(values)

    const { origin } = this.context.selectedRange

    const range = intersection(
      makeRange(origin, {
        x: origin.x + size.x - 1,
        y: origin.y + size.y - 1,
      }),
      this.context.grid.rect
    )

    forEachOfRange(range, (point) => {
      const value =
        values[point.y - range.start.y][point.x - range.start.x] ?? ""
      const cell = this.getCellAtPoint(point)
      cell.setInput(value)
      cell.apply()
    })

    this.context.selectedRange.selectRange(range)
  }
}

export default EditManager

const getMatrixSize = (values: string[][]): Point => {
  const rows = values.length
  const cols = max(values.map((it) => it.length)) ?? 0
  return { x: cols, y: rows }
}

const stringifyClipboardMatrix = (values: string[][]) => {
  return values.map((it) => it.join("\t")).join("\r\n")
}

const parseClipboardMatrix = (text: string) => {
  return text.split("\r\n").map((it) => it.split("\t"))
}
