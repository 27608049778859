import React, { useMemo, useCallback } from "react"
import { observer } from "mobx-react-lite"
import sortBy from "lodash/sortBy"
import { useNavigate } from "react-router-dom"

import { useStore } from "@store"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import {
  fileSourcesNames,
  dataConnectorsNames,
  webSideNames,
} from "@framework/types/upload"
import { getDataSourceDetails } from "@utils/product-solution"
import mainRoutes from "@root/main.routes"

import styles from "./HomeInfo.module.sass"

const dataSourceOptions = sortBy(
  [...dataConnectorsNames, ...fileSourcesNames, ...webSideNames].map(
    getDataSourceNodeContextProps
  ),
  (it) => it.label
)

const DataSources = observer(() => {
  const {
    uploadStore: { dataSourceDetails },
    restrictionsStore: access,
  } = useStore()
  const navigate = useNavigate()

  const { isLoading, data } = dataSourceDetails.state

  const validOptions = useMemo(
    () =>
      dataSourceOptions.filter((it) => access.testDataSourceAccess(it.name)),
    [dataSourceOptions, access]
  )

  const dataSourceDetailsMemo = useMemo(() => {
    return new Map(
      dataSourceOptions.map((source) => [
        source.name,
        getDataSourceDetails(dataSourceDetails.dataMap.get(source.name)),
      ])
    )
  }, [dataSourceDetails.dataMap, dataSourceOptions])

  const filteredValidOptions = useMemo(() => {
    return validOptions
      .filter(
        (source) =>
          (dataSourceDetailsMemo.get(source.name)?.totalAccounts ?? 0) > 0
      )
      .sort((a, b) => {
        const countA = dataSourceDetailsMemo.get(a.name)?.totalAccounts ?? 0
        const countB = dataSourceDetailsMemo.get(b.name)?.totalAccounts ?? 0
        return countB - countA
      })
  }, [validOptions, dataSourceDetailsMemo])

  const navigateToDataSource = useCallback(
    () => navigate(mainRoutes.upload()),
    [navigate]
  )

  if (isLoading && !data) {
    return <Loader size="large" fluid />
  }

  if (data.length === 0) {
    return <NotFound>No Data Types found</NotFound>
  }

  return (
    <div className={styles.sectionList2}>
      <div className={styles.listWrapper2}>
        {filteredValidOptions.map((source) => (
          <div
            onClick={navigateToDataSource}
            onKeyPress={(e) => {
              if (e.key === "Enter" || e.key === " ") navigateToDataSource()
            }}
            role="button"
            tabIndex={0}
            key={source.name}
            className={styles.product}
          >
            <div className={styles.iconWrapper}>
              <DocumentIcon className={styles.productIcon} icon={source.icon} />
              <span>{source.label}</span>
            </div>
            <span>{dataSourceDetailsMemo.get(source.name)?.totalAccounts}</span>
          </div>
        ))}
      </div>
    </div>
  )
})

export default DataSources
