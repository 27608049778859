import {
  SolutionCategory,
  SolutionData,
  SolutionEntity,
} from "@framework/types/solution"
import { DataSourceStatusReport } from "@framework/types/upload"

export const getAvailableCategories = (
  categories: SolutionCategory[],
  solutions: SolutionEntity[]
): SolutionCategory[] => {
  const availableCategories =
    solutions?.map((item) => item.category?.toLowerCase()) || []

  return categories.filter((item) =>
    availableCategories.includes(item.category?.toLowerCase())
  )
}

export const getCategorySolutions = (
  solutionCategory: SolutionCategory,
  solutions: SolutionData[]
): SolutionData[] =>
  solutions.filter(
    (solution) =>
      solution.category?.toLowerCase() ===
      solutionCategory.category?.toLowerCase()
  )

export const getDataSourceDetails = (data?: DataSourceStatusReport) => {
  if (data == null) return null
  if (data.sourceType === "data-connector") {
    const totalAccounts = Number(data.totalAccounts ?? 0)
    const totalFolders = Number(data.totalFolders ?? 0)
    const totalFiles = Number(data.totalFiles ?? 0)

    return {
      totalAccounts,
      totalFolders,
      totalFiles,
    }
  }

  const totalFiles = Number(data?.count ?? 0)

  return {
    totalAccounts: 0,
    totalFolders: 0,
    totalFiles,
  }
}
