import React from "react"
import { matchPath, useLocation, useNavigate } from "react-router-dom"

import useToggle from "@components/hooks/useToggle"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Avatar from "@components/ui/Avatar/Avatar"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import { Option } from "@framework/types/utils"
import { useStore } from "@store/index"
import Text from "@components/ui/Typography/Text"

import MenuItem from "./MenuItem"
import { NavItemTooltip } from "./NavItemTooltip"

import styles from "./UserSettingsMenu.module.sass"

export interface UserSettingsMenuProps {
  menuOptions?: Option[]
  showMore?: boolean
}

const UserSettingsMenu: React.FC<UserSettingsMenuProps> = ({
  menuOptions = [],
  showMore,
}) => {
  const location = useLocation()
  const navigate = useNavigate()

  const {
    userStore: { user, fullName },
  } = useStore()

  const { showModal } = useModal(ModalsTypes.LOGOUT_MODAL)
  const { isOpened, handleToggle, setOpened } = useToggle()

  const handleLogout = () => {
    showModal({})
  }

  const handleMenu = (name: string) => () => {
    navigate(`/${name}`)
  }

  const isAvatarActive = Boolean(
    menuOptions.find(
      ({ name }) => matchPath(`/${name}`, location.pathname) != null
    )
  )

  const handleProfile = () => {
    const option = menuOptions.find((item) => item.name === "profile")
    if (option) navigate(`/${option.name}`)
  }

  return showMore ? (
    <div className={styles.profileWrapper}>
      <div
        className={styles.profileInfo}
        onClick={handleProfile}
        tabIndex={0}
        role="button"
        onKeyPress={(e) => {
          if (e.key === "Enter") handleProfile()
        }}
      >
        <Avatar
          name={fullName}
          active={isAvatarActive}
          src={user?.avatarURL ?? ""}
        />
        <Text
          className={styles.nameText}
          variant="h5"
          color="text100Color"
        >{`${user?.firstName} ${user?.lastName}`}</Text>
      </div>
      <div className={styles.profileBtnWrapper}>
        {showMore && (
          <MenuItem
            icon="log-out"
            onClick={handleLogout}
            key="logout"
            className={styles.logoutBtnBig}
          />
        )}
      </div>
    </div>
  ) : (
    <NavItemTooltip
      text={<Text variant="h5">Profile</Text>}
      disabled={isOpened}
    >
      <Tooltip
        show={isOpened}
        onClose={() => setOpened(false)}
        mode="onFocus"
        content={
          <TooltipContainer placement="right">
            {menuOptions?.map((it) => (
              <MenuItem
                color="dark"
                icon={it.icon}
                onClick={handleMenu(it.name)}
                key={it.name}
              >
                {it.value}
              </MenuItem>
            ))}
            <MenuItem
              color="red"
              icon="log-out"
              onClick={handleLogout}
              key="logout"
            >
              Log out
            </MenuItem>
          </TooltipContainer>
        }
      >
        <Avatar
          className={styles.avatar}
          name={fullName}
          active={isAvatarActive}
          src={user?.avatarURL ?? ""}
          onClick={handleToggle}
        />
      </Tooltip>
    </NavItemTooltip>
  )
}

export default UserSettingsMenu
