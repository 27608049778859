import { observer } from "mobx-react-lite"
import React from "react"

import { ModalsTypes } from "@components/modals/constants"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import useModal from "@components/modals/useModal"
import Button from "@components/ui/Button/Button"
import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"

import styles from "./ConfirmModal.module.sass"

export interface ConfirmModalProps {
  message: string
  confirm?: "negative" | "positive"
  confirmButtonText?: string
  rejectButtonText?: string
  onConfirm?: () => void
  onReject?: () => void
}

export const ConfirmModal: React.FC<ConfirmModalProps> = observer(
  ({
    onConfirm,
    onReject,
    message,
    confirm = "positive",
    confirmButtonText = "Confirm",
    rejectButtonText = "Cancel",
  }) => {
    const { hideModal } = useModal(ModalsTypes.CONFIRM_MODAL)

    return (
      <BaseModal
        title={<ModalTitle titleText={message} />}
        className={styles.root}
        containerClassName={styles.container}
        onClose={() => {
          hideModal()
          onReject?.()
        }}
      >
        <ModalFooterContainer className={styles.footer}>
          <Button
            variant="outlined"
            onClick={() => {
              hideModal()
              onReject?.()
            }}
          >
            {rejectButtonText}
          </Button>
          <Button
            color={confirm === "negative" ? "red" : "primary"}
            onClick={() => {
              hideModal()
              onConfirm?.()
            }}
          >
            {confirmButtonText}
          </Button>
        </ModalFooterContainer>
      </BaseModal>
    )
  }
)

export default ConfirmModal
