import { makeAutoObservable, when } from "mobx"

import {
  createMatrix,
  createMatrixFromSnapshot,
} from "@components/ui/Spreadsheet/createMatrix"
import MatrixStore from "@components/ui/Spreadsheet/state/MatrixStore"

import CompaniesStore from "./companies.store"

const WORKBOOK_SESSION_KEY = "WORKBOOK_SESSION"

export default class UnifiedMatrixStore {
  private instance: MatrixStore | null

  companiesStore: CompaniesStore

  localSnapshot: string | null

  isLoading: boolean = false

  error: string | null = null

  constructor() {
    this.instance = null
    this.companiesStore = new CompaniesStore()

    this.localSnapshot = localStorage.getItem(WORKBOOK_SESSION_KEY)

    makeAutoObservable(this)
  }

  get matrixStore(): MatrixStore | null {
    return this.instance
  }

  get isLocalSessionExist() {
    return this.localSnapshot != null
  }

  initNewInstance = async () => {
    try {
      this.isLoading = true
      this.error = null

      await when(
        () =>
          this.companiesStore.companies != null && !this.companiesStore.error
      )

      const newInstance = createMatrix({
        initialValue: [["Company", "Category", "Business Unit", "Pain points"]],
      })

      if (this.companiesStore.companies != null) {
        const companyNames = this.companiesStore.companies.map((it) => it.name)

        if (!newInstance.isValidationRuleRegistered("DROPDOWN_COMPANY"))
          newInstance.registerValidationRule("DROPDOWN_COMPANY", {
            type: "OPTION",
            list: companyNames,
          })

        newInstance.applyValidationRule("A2:A100", "DROPDOWN_COMPANY")
      }

      this.instance = newInstance

      return this.instance
    } catch (error) {
      this.error = "Initialization failed"
    }

    return null
  }

  restoreInstance = () => {
    if (this.localSnapshot == null)
      throw new Error("Matrix snapshot do not exists")

    const snapshot = JSON.parse(this.localSnapshot)

    this.instance = createMatrixFromSnapshot(snapshot)

    return this.instance
  }

  saveSnapshot = (snapshot: any) => {
    this.localSnapshot = JSON.stringify(snapshot)
    localStorage.setItem(WORKBOOK_SESSION_KEY, this.localSnapshot)
  }

  dropSnapshot = () => {
    this.localSnapshot = null
    localStorage.removeItem(WORKBOOK_SESSION_KEY)
  }
}
