import { makeAutoObservable } from "mobx"

import { Point, Range } from "@framework/types/common"

import {
  getFocusedNode,
  Parser,
  ParseResult,
  traceFocusedNode,
  tokenize,
  setFocusedNode,
} from "../parser"
import { stringifyFormula } from "../parser/utils"
import { refToRange } from "../utils"
import FunctionManager from "./FunctionManager"
import { FunctionDescription } from "../types"
import { Function } from "../parser/types"

const formulaParser = new Parser()

class CellEditorState {
  // injections

  functionManager: FunctionManager

  // state

  input: any

  touched: boolean

  formula: ParseResult | null

  error: string | null

  inputSelection: Range<number>

  autoFocusCell: boolean = true

  suggestion: {
    formula: ParseResult
    nodePath: (string | number)[]
    functions: FunctionDescription[]
  } | null

  constructor(options: {
    initialValue: any
    functionManager: FunctionManager
    autoFocusCell?: boolean
  }) {
    this.functionManager = options.functionManager

    this.input = options.initialValue ?? ""
    this.formula = null
    this.suggestion = null
    this.error = null
    this.autoFocusCell = options.autoFocusCell ?? true
    this.touched = false
    this.inputSelection = { start: -1, end: -1 }

    this.process()

    makeAutoObservable(this)
  }

  get refEntries(): [string, Range<Point>][] {
    const refs = this.formula?.refs ?? []
    return refs.map((it) => [it, refToRange(it)])
  }

  setInput = (value?: string) => {
    this.input = value
    this.touched = true
    this.process()
  }

  process = () => {
    const text = this.input

    this.formula = null
    this.error = null

    if (typeof text !== "string") return

    if (!text?.startsWith("=")) return

    const tokens = tokenize(text.substring(1))

    this.formula = formulaParser.parse(tokens)

    this.getSuggestions()
  }

  normalize = () => {
    try {
      if (this.formula != null) {
        if (!this.formula?.isValid) throw new Error("Formula parsing error")

        const normalFormulaStr = stringifyFormula(this.formula.matched, true)

        this.formula = formulaParser.parse(tokenize(normalFormulaStr))
        this.input = `=${normalFormulaStr}`
      }
    } catch (error: any) {
      this.error =
        typeof error.message === "string" ? error.message : "Unexpected error"
    }
    return this.input
  }

  setSelection = (selection: Range<number>) => {
    this.inputSelection = selection
    this.getSuggestions()
  }

  getSuggestions = () => {
    const { inputSelection, formula } = this

    this.suggestion = null

    if (formula == null) return
    if (inputSelection.start < 0 || inputSelection.end < 0) return
    if (inputSelection.start !== inputSelection.end) return

    const nodePath = traceFocusedNode(formula.matched, inputSelection.end - 1)
    if (nodePath == null) return

    const node = getFocusedNode(formula.matched, nodePath)
    if (node == null) return

    if (node.type !== "unknown" && node.type !== "const") return

    const functions = this.functionManager.findFunctions(node.token)

    this.suggestion = {
      formula,
      nodePath,
      functions,
    }
  }

  applyFunctionSuggestion = (func: FunctionDescription) => {
    if (this.suggestion == null) return

    const { formula, nodePath: path } = this.suggestion

    const newNode: Function = {
      type: "func",
      name: func.name,
      token: func.name,
      open: true,
      closed: false,
      arguments: [],
    }

    formula.matched = setFocusedNode(formula.matched, path, newNode)

    this.setInput(`=${stringifyFormula(formula.matched)}`)
  }
}

export default CellEditorState
