import clsx from "clsx"
import React from "react"
import { observer } from "mobx-react-lite"
import toString from "lodash/toString"

import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"

import { useMatrixContext } from "./MatrixContext"
import { stringifyPoint, pointToCode, includes } from "./utils"
import Icon from "../Icon/Icon"
import TruncatedText from "../Typography/TruncatedText"
import MagicTextLoader from "../Loader/MagicTextLoader"
import useContextMenu from "../ContextMenu/useContextMenu"

import styles from "./Cell.module.sass"

type CellProps = {
  columnIndex: number
  rowIndex: number
}

const Cell: React.FC<CellProps> = observer(({ columnIndex, rowIndex }) => {
  const contextMenu = useContextMenu()

  const context = useMatrixContext()

  const {
    editManager,
    selectedRange,
    spreadRange,
    contextMenuManager,
    updateSpreading,
  } = context

  const longTextModal = useModal(ModalsTypes.LONG_TEXT_MODAL)

  const currentCellPoint = { x: columnIndex, y: rowIndex }

  const handleMouseDown = (e: React.MouseEvent) => {
    editManager.submitCell()

    if (e.button === 2) {
      if (!includes(selectedRange.range, currentCellPoint)) {
        selectedRange.startRange(currentCellPoint)
      }
      e.stopPropagation()
      return
    }

    selectedRange.startRange(currentCellPoint)
  }

  const handleMouseEnter = (e: React.MouseEvent) => {
    if (selectedRange.rangeStarted) {
      selectedRange.updateRange(currentCellPoint)
    }
    if (spreadRange?.rangeStarted) {
      updateSpreading(currentCellPoint)
    }
  }

  const handleMouseDoubleClick = (e: React.MouseEvent) => {
    editManager.editCell(currentCellPoint)
  }

  const cell = editManager.findCell(currentCellPoint)

  const isFailed = !!cell?.state.error

  const renderValue = (value: any) => {
    const content = value == null ? "" : toString(value)

    return (
      <TruncatedText
        maxLines={content?.length > 450 ? 2 : undefined}
        truncated
        onReadMoreClick={() =>
          longTextModal.showModal({
            title: pointToCode(currentCellPoint),
            content,
          })
        }
      >
        {content}
      </TruncatedText>
    )
  }

  return (
    <div
      className={clsx(styles.root, { [styles.withError]: isFailed })}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onDoubleClick={handleMouseDoubleClick}
      onContextMenu={(e) => {
        const menu = contextMenuManager.getContextMenu()
        if (menu) contextMenu.handleContextMenu(e, menu)
      }}
      aria-label={stringifyPoint(currentCellPoint)}
      role="button"
      tabIndex={-1}
    >
      {cell != null && (
        <>
          <div className={clsx(styles.content)}>
            {cell.state.isLoading ? (
              <MagicTextLoader>Processing...</MagicTextLoader>
            ) : (
              renderValue(cell.value)
            )}
          </div>

          {cell.validationRuleId &&
            context.validationManager.getRuleByName(cell.validationRuleId)
              .type === "OPTION" && (
              <button
                type="button"
                className={clsx(styles.arrow)}
                onClick={(e) => {
                  editManager.editCell(currentCellPoint)
                  e.stopPropagation()
                }}
              >
                <Icon name="arrow-down" />
              </button>
            )}
        </>
      )}
      {isFailed && (
        <span className={styles.error} title={cell.state.error || undefined}>
          !
        </span>
      )}
    </div>
  )
})

export default Cell
