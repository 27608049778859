import { rangeToCode, refToRange } from "../utils"
import { Formula, Marker } from "./types"

import { tokenize } from "."

export const stringifyFormula = (
  formula: Formula | Marker,
  normalize: boolean = false
): string => {
  if (!("type" in formula))
    return transformInCondition(formula.token, normalizeToken, normalize)

  switch (formula.type) {
    case "un-exp": {
      const operator = transformInCondition(
        formula.token,
        normalizeToken,
        normalize
      )

      return `${operator}${stringifyFormula(formula.value, normalize)}`
    }

    case "bin-exp": {
      const leftText = stringifyFormula(formula.left, normalize)

      const rightText = stringifyFormula(formula.right, normalize)

      const operator = transformInCondition(
        formula.token,
        normalizeToken,
        normalize
      )

      return `${leftText}${operator}${rightText}`
    }

    case "func": {
      const funcName = transformInCondition(
        formula.token,
        normalizeFuncName,
        normalize
      )

      const argsText = formula.arguments
        .map((it) => stringifyFormula(it, normalize))
        .join(",")

      const openBracket = formula.open || normalize ? "(" : ""

      const closeBracket = formula.closed || normalize ? ")" : ""

      return `${funcName}${openBracket}${argsText}${closeBracket}`
    }

    case "ref": {
      return transformInCondition(formula.token, normalizeRef, normalize)
    }

    case "const":
    case "unknown": {
      return transformInCondition(formula.token, normalizeToken, normalize)
    }

    default:
      return ""
  }
}

export const transformInCondition = <T,>(
  value: T,
  callback: (value: T) => T,
  shouldTransform: boolean
) => (shouldTransform ? callback(value) : value)

export const normalizeToken = (token: string) => token.trim()

export const normalizeFuncName = (token: string) =>
  normalizeToken(token).toUpperCase()

export const compareRefs = (a: string, b: string) =>
  a === b ? 0 : a.length > b.length ? 1 : -1

export const normalizeRef = (token: string) =>
  rangeToCode(refToRange(normalizeToken(token).toUpperCase()))

export const replaceRefs = (
  formulaText: string,
  shiftedRefs: Record<string, string>
) => {
  return tokenize(formulaText)
    .map((it) => (shiftedRefs[it] ? shiftedRefs[it] : it))
    .join("")
}
