import React from "react"

import Icon from "@components/ui/Icon/Icon"
import { useStore } from "@store"

import DataTypes from "./DataTypes"
import useAvatarScope from "../AvatarScopeContext/useAvatarScope"
import ActiveAvatarContextProvider from "../ActiveAvatarContext"
import DataSources from "./DataSources"

import styles from "./HomeInfo.module.sass"

const HomeInfo: React.FC = () => {
  const { solutionsStore, restrictionsStore: access } = useStore()
  const { avatar } = useAvatarScope()

  const { category } = solutionsStore
  return (
    <div className={styles.sidebar}>
      <div className={styles.infoSection}>
        <div className={styles.tag}>{category?.category}</div>
        <div className={styles.heading}>{category?.heading}</div>
        <p>{category?.description}</p>
      </div>
      {access.isProductsEnable && (
        <div className={styles.section}>
          <h3>Competitor Products</h3>
          <div className={styles.sectionList1}>
            <div className={styles.product}>
              <div>
                <Icon className={styles.productIcon} name="box" />
                <span>My Products</span>
              </div>
              <span>0</span>
            </div>
            <div className={styles.product}>
              <div>
                <Icon className={styles.productIcon} name="storage" />
                <span>Other Products</span>
              </div>
              <span>0</span>
            </div>
          </div>
        </div>
      )}
      <div className={styles.section}>
        <h3>Data Types</h3>
        {avatar?.id !== null && (
          <ActiveAvatarContextProvider avatarId={avatar?.id}>
            <DataTypes />
          </ActiveAvatarContextProvider>
        )}
      </div>
      <div className={styles.section}>
        <h3>Data Sources</h3>
        <DataSources />
      </div>
    </div>
  )
}

export default HomeInfo
