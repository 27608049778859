import React, { useMemo, useState } from "react"
import { observer } from "mobx-react-lite"
import groupBy from "lodash/groupBy"

import { Option } from "@framework/types/utils"
import mainRoutes from "@root/main.routes"
import { useStore } from "@store"
import { convertFirstLetterToUpper } from "@utils/textUtils"
import useAvatarScope from "@pages/search/AvatarScopeContext/useAvatarScope"

export interface NavigationScopeContextData {
  allowedNavOptions: Option[]
  allowedProfileOptions: Option[]
  setActiveOption: (option: Option) => void
  activeOption?: Option
}

export const NavigationScopeContext =
  React.createContext<NavigationScopeContextData>({
    allowedNavOptions: [],
    allowedProfileOptions: [],
    setActiveOption: (option: Option) => {},
  })

export type Props = {}

export const NavigationScopeContextProvider: React.FC<Props> = observer(
  ({ children }) => {
    const { restrictionsStore: access, solutionsStore } = useStore()
    const [activeOption, setActiveOption] = useState<Option>()
    let subOptions: Option[] = []
    // home start
    const { solutions } = solutionsStore

    const allowedNavOptions = useMemo(() => {
      const grouped = groupBy(solutions, "category")
      const orderedKeys = ["general", "prospect", "sell", "learn"]
      const groupedSolutions = Object.fromEntries(
        orderedKeys
          .filter((key) => key in grouped)
          .map((key) => [key, grouped[key]])
      )
      subOptions = Object.keys(groupedSolutions)
        .filter((key) => key !== "null")
        .map((key) => ({
          name: `${mainRoutes.home()}?category=${key}`,
          value: convertFirstLetterToUpper(key),
          icon: "jigsaw-puzzle",
          subOptions: groupedSolutions?.[key].map((item) => ({
            name: `${mainRoutes.home()}?category=${key}&solutions=${item.id}`,
            value: convertFirstLetterToUpper(item.name),
            icon: "jigsaw-puzzle",
          })),
        }))

      const navList: Option[] = [
        {
          name: mainRoutes.home(),
          value: "Home",
          icon: "home",
          subOptions,
        },
      ]

      // home end

      if (access.isDataSourcesAvailable)
        navList.push({
          name: mainRoutes.upload(),
          value: "Library",
          icon: access.canEditDataSource ? "upload-cloud" : "cloud",
        })

      // Analytics Start
      if (
        access.isAppUsageAnalyticsAvailable ||
        access.isAPIUsageAnalyticsAvailable
      ) {
        subOptions = []
        if (access.isAppUsageAnalyticsAvailable) {
          subOptions.push({
            name: mainRoutes.usageAnalytics(),
            value: "Usage Analytics",
            icon: "stats-random",
          })
        }
        if (access.isAPIUsageAnalyticsAvailable) {
          subOptions.push({
            name: mainRoutes.apiUsageAnalytics(),
            value: "API Usage Analytics",
            icon: "stats-random",
          })
        }
        navList.push({
          name: mainRoutes.analytics(),
          value: "Analytics",
          icon: "stats-random",
          subOptions,
        })
      }

      // Analytics end

      // Experts start
      const expertsNav: Option = {
        name: mainRoutes.experts(),
        value: "Experts",
        icon: "users",
        subOptions: [
          {
            name: mainRoutes.experts(),
            value: "Leaderboard",
            icon: "users",
          },
        ],
      }
      subOptions = []
      if (access.isExpertInsightsAvailable)
        subOptions.push({
          name: mainRoutes.expertInsights(),
          value: "Insights",
          icon: "user-insight",
        })

      if (access.isQuestionsSectionAvailable)
        subOptions.push({
          name: mainRoutes.questions(),
          value: "Questions",
          icon: "questions-chat",
        })
      expertsNav.subOptions = [...(expertsNav?.subOptions || []), ...subOptions]

      navList.push(expertsNav)
      // Experts end

      if (access.isLatestUpdatesAvailable)
        navList.push({
          name: mainRoutes.updates(),
          value: "Latest Updates",
          icon: "loader",
        })

      // Admin panel starts
      if (access.isAdminPanelAvailable) {
        subOptions = []

        if (access.isUsersListAvailable) {
          subOptions.push({
            name: mainRoutes.adminPanelTab("users"),
            value: "Users",
            icon: "terminal-window-line",
          })
        }
        if (access.onlySuperAdminAccess) {
          subOptions.push({
            name: mainRoutes.adminPanelTab("roles"),
            value: "Roles & Actions",
            icon: "terminal-window-line",
          })
          subOptions.push({
            name: mainRoutes.adminPanelTab("features"),
            value: "Feature Flags",
            icon: "terminal-window-line",
          })
          subOptions.push({
            name: mainRoutes.adminPanelTab("config"),
            value: "Instance Config",
            icon: "terminal-window-line",
          })
        }

        if (access.isGlossaryAvailable) {
          subOptions.push({
            name: mainRoutes.adminPanelTab("glossary"),
            value: "Glossary",
            icon: "terminal-window-line",
          })
        }
        if (access.isAvatarsAvailable) {
          subOptions.push({
            name: mainRoutes.adminPanelTab("avatars"),
            value: "Avatars",
            icon: "terminal-window-line",
          })
        }
        subOptions.push({
          name: mainRoutes.adminPanelTab("datatypes"),
          value: "Datatypes",
          icon: "storage",
        })
        if (access.canAccessAPIKeys) {
          subOptions.push({
            name: mainRoutes.adminPanelTab("api-keys"),
            value: "Api Keys",
            icon: "terminal-window-line",
          })
        }
        if (access.isSubscriptionPageAvailable) {
          subOptions.push({
            name: mainRoutes.adminPanelTab("subscription"),
            value: "Subscription",
            icon: "terminal-window-line",
          })
        }

        navList.push({
          name: mainRoutes.adminPanel(),
          value: "Admin panel",
          icon: "terminal-window-line",
          subOptions,
        })
      }
      // Admin panel ends

      return navList
    }, [
      access.isAvatarsAvailable,
      access.isDataSourcesAvailable,
      access.canEditDataSource,
      access.isQuestionsSectionAvailable,
      access.isLatestUpdatesAvailable,
      solutions,
    ])

    const allowedProfileOptions = useMemo(() => {
      const navList: Option[] = []

      navList.push({ value: "Settings", name: "profile", icon: "settings" })

      return navList
    }, [])
    const context: NavigationScopeContextData = {
      allowedProfileOptions,
      allowedNavOptions,
      activeOption,
      setActiveOption,
    }

    return (
      <NavigationScopeContext.Provider value={context}>
        {children}
      </NavigationScopeContext.Provider>
    )
  }
)

export default NavigationScopeContextProvider
