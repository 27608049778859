import clsx from "clsx"
import React from "react"
import { useLocation } from "react-router-dom"

import { Icon } from "@components/ui/Icon/Icon"
import { IconName } from "@components/ui/Icon/IconName"
import { Option } from "@framework/types/utils"

import { checkHomePageActive } from "./ExandableNavItem"

import styles from "./NavItem.module.sass"

export interface NavItemProps {
  icon?: IconName
  label?: string
  mark?: React.ReactNode
  className?: string
  onClick?: () => void
  isActive?: boolean
  name?: string
  option: Option
}
const getFirstPathSegment = (path: string) => path.split("/")?.[1]

export const NavIcon: React.FC<NavItemProps> = ({
  icon,
  label,
  mark,
  className,
  onClick,
  name,
  option,
  ...rest
}) => {
  const location = useLocation()

  const isActive = React.useMemo(() => {
    const currentPathSegment = getFirstPathSegment(location.pathname)
    return (
      getFirstPathSegment(option.name) === currentPathSegment ||
      option?.subOptions?.some(
        (item) => getFirstPathSegment(item.name) === currentPathSegment
      )
    )
  }, [location.pathname, option])
  let isHomePageActive = false
  if (option.name === "/home" && checkHomePageActive(location.pathname)) {
    isHomePageActive = true
  }

  return (
    <div
      onClick={onClick}
      role="button"
      tabIndex={0}
      className={clsx(
        styles.root,
        { [styles.active]: isHomePageActive || isActive },
        className
      )}
      {...rest}
      onKeyPress={(e) => {
        if (e.key === "Enter" || e.key === " ") onClick?.()
      }}
    >
      <span className={styles.icon}>
        <Icon name={icon} />
        <span className={styles.badge}>{mark}</span>
      </span>
      {label && <span className={styles.label}>{label}</span>}
    </div>
  )
}

export default NavIcon
